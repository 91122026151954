import axios from 'axios'
let baseURL = '/api'
import { getToken, removeToken, getAppVersion, getOsType } from '@/utils/auth'
import { LOGIN_INVALID_CODES } from '@/enum'
import store from '@/store/index'

const service = axios.create({
    withCredentials: true,
    crossDomain: true,
    baseURL,
    timeout: 60000
})

// request拦截器,在请求之前做一些处理
service.interceptors.request.use(
    config => {
		store.commit('showLoading')
        config.headers['token'] = getToken() || ''
        config.headers['os-type'] = getOsType() || ''
        config.headers['app-id'] = 'zj3eAYdxA8'
        config.headers['app-version'] = getAppVersion() || ''
        return config;
    },
    error => {
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

//配置成功后的拦截器
service.interceptors.response.use(async res => {
    store.commit('hideLoading')
    if (res.status == 200) {
        let { code } = res.data
        if (code == 200) return res.data.result
        else {
            return Promise.reject(res)
        }
    } else {
        return Promise.reject(res);
    }
}, error => {
    store.commit('hideLoading')
    return Promise.reject(error)
})

async function http(param, isShowError = true) {
    try {
        let res = await service(param)
        return res
    } catch (error) {
        console.error(error)
        isShowError && alert(error?.data?.msg || error?.data?.message || '系统异常')
        return Promise.reject(error)
    }
}

export {
    http,
    service,
}