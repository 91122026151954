import {setToken, setAppVersion, setOsType} from '@/utils/auth'

export const authMixin = {
    created() {
        const {appVersion, osType, token} = this.$route.query

        // alert(this.$route.fullPath);

        appVersion && setAppVersion(appVersion)
        osType && setOsType(osType)
        token && setToken(token)
    },
}