<template>
  <div class="advance">
    <div class="cover">
      <img src="./images/cover.png" alt="">
    </div>

    <div class="detail" v-for="(item, index) in productAdvances" :key="index">
      <h2>{{item.title}}</h2>
      <p v-if="item.subTitle">{{item.subTitle}}</p>

      <img :src="url" alt="" v-for="url in item.imgUrls || []" :key="url" />
    </div>
  </div>
</template>
  
<script>
import {apiProductAdvance} from './api'
import {authMixin} from '@/mixins/authMixin.js'
export default {
  name: 'ProductAdvance',

  mixins: [authMixin],

  data() {
    return {
      productAdvances: []
    }
  },

  mounted() {
    this.getProductAdvance()
  },

  methods: {
    async getProductAdvance() {
      this.productAdvances = await apiProductAdvance()
    }
  }
}
  </script>
  
<style lang="scss" scoped>
.advance {
  .cover {
    width: 100%;
    padding: 0.32rem 0.32rem 0rem 0.32rem;
    box-sizing: border-box;
    img {
      width: 100%;
    }
  }

  .detail {
    background-color: #fff;
    margin: 0rem 0.32rem 0.32rem 0.32rem;
    padding: 0rem 0.32rem 0rem 0.32rem;
    border-radius: 0.2133rem;

    h2 {
      font-size: 0.48rem;
      height: 1.0933rem;
      line-height: 1.0933rem;
      text-align: center;
      color: #333333;
    }

    p {
      font-size: 0.3733rem;
      color: #666666;
      text-align: center;
      padding-bottom: 0.2133rem;
    }

    img {
      width: 100%;
    }
  }
}
</style>
  