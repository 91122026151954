import { http } from '@/http/axios.js'

export function apiCreditPartnerList(pageNo=1) {
    return http({
      url: `/zjapi/credit/partner/page?pageNo=${pageNo}`,
      method: 'GET',
    })
  }
  

export function apiChannelTrack(sourceId) {
  return http({
    url: `/zjapi/channel/track`,
    method: 'post',
    data: {
      sourceType: 2,
      sourceId
    }
  })
}
