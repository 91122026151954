import Cookies from 'js-cookie'
const TokenKey = 'loginToken'
const OS_TYPE = 'osType'
const APP_VERSION = 'appVersion'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { expires: 30 })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getOsType() {
  let osType =  Cookies.get(OS_TYPE)
  if (osType) {
    return osType;
  }
  return 1;
}

export function setOsType(osType) {
  return Cookies.set(OS_TYPE, osType, { expires: 30 })
}

export function removeOsType() {
  return Cookies.remove(OS_TYPE)
}

export function getAppVersion() {
  let appVersion = Cookies.get(APP_VERSION)
  if (appVersion) {
    return appVersion;
  }
  return 'v1.1'
}

export function setAppVersion(appVersion) {
  return Cookies.set(APP_VERSION, appVersion, { expires: 30 })
}

export function removeAppVersion() {
  return Cookies.remove(APP_VERSION)
}