<template>
  <div class="content">
    <div class="qrcode">
      <img src="./images/qrcode-cb.png" alt="">
    </div>
    <img src="./images/qrcode-bg.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    position: relative;
    img {
        width: 100%;
    }

    .qrcode {
        position: absolute;
        top: 3.52rem;
        left: 2.9867rem;
        width: 4.08rem;
        height: 4.08rem;
        img {
            width: 100%;
        }
    }
}
</style>