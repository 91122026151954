<template>
  <div class="protocolBody">
    <h1>{{protocol.title}}</h1>
    <div class="content" v-html="protocol.content"></div>
  </div>
</template>

<script>
import {apiAgreement} from './api'
export default {
    name: 'Protocol',
    data() {
        return {
            protocol: {
                title: '',
                content: ''
            }
        }
    },
    async created() {
        let id = this.$route.params.id

        console.log(id, 'iiii');
        let res = await apiAgreement(id || 1);
        if (res) {
            this.protocol = res
            document.title = res.title || '协议'
        }
    }
}
</script>

<style lang="scss" scoped>
    .protocolBody {
        padding: .4rem .2667rem;
        background: #fff;
        h1 {
            font-size: .4267rem;
            text-align: center;
            margin: .1333rem 0rem .4rem 0rem;
        }

        div {
            font-size: .3733rem;
            line-height: 150%;
        }
    }
</style>