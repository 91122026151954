<template>
  <div class="tc">
    <div class="top">
      <div class="bg">
        <img src="https://assets-static.kuaigouhua.com/zuji/bg.88b4a819.png?x-oss-process=image/format,webp" />
      </div>

      <div class="text-desc">
        <!-- <img class="logo-img" src="./images/logo.png" alt=""> -->
        <h2>0预付</h2>
        <h1>租 iPhone 14</h1>
<!--        <img src="./images/ok-zuji.png" />-->
        <ul>
          <li><img src="./images/myj.png" />免押金</li>
          <li><img src="./images/pld.png" />非监管机</li>
          <li><img src="./images/shk.png" />顺丰包邮</li>
        </ul>
      </div>
    </div>

    <div class="center">
      <div class="mobile">
        <van-form validate-first style="width: 100%" :show-error-message="true" @submit="onSubmit">
          <div class="phone-input">
            <div class="left">
              <span>+86</span>
              <span class="circle"></span>
            </div>
            <van-field
              @blur="onBlur"
              class="right"
              v-model="phone"
              name="pattern"
              placeholder="请输入手机号码"
              :change="onChange"
              :rules="[
                { required: true, message: '手机号不能为空' },
                { pattern, message: '请输入正确手机号码' }
              ]"
            />
          </div>
          <div class="protocol">
            <input type="checkbox" v-model="isAgree" />
            <span
              >我已经阅读并同意
              <em v-for="(item, index) in agreementList" :key="item.id" @click="goProtocol(item.id)">
                {{ item.title }}
                <span v-if="index != agreementList.length - 1">、</span>
              </em>
            </span>
          </div>
          <div class="go-zuji">
            <!-- <button>去租机</button> -->
            <van-button :disabled="btnCheck" round block type="info" native-type="submit">去租机</van-button>
          </div>
        </van-form>
      </div>
    </div>
    <div class="footer">
      <a href="https://beian.miit.gov.cn/#/Integrated/index">杭州网沙科技有限公司/ 浙ICP备2022009049号-5</a>
    </div>
  </div>
</template>

<script>
import { apiTrafficConfig, apiTrafficLog, apiAgreementList, } from './api'
import { authMixin } from '@/mixins/authMixin.js'
export default {
  name: 'Traffic',

  mixins: [authMixin],

  data() {
    return {
      isAgree: false,
      phone: '',
      trafficCode: '',
      rentUrl: '',
      agreementList: [],
      pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
      checkPhone: false
    }
  },

  beforeMount() {
    this.getAgreementList()
  },

  mounted() {
    const { tc } = this.$route.query
    this.trafficCode = tc
    this.getTrafficConfig()
  },

  computed: {
    btnCheck() {
      let check = true
      if (this.checkPhone == true && this.isAgree == true) {
        check = false
      } else {
        check = true
      }
      return check
    }
  },
  methods: {
    onBlur() {
      const res = this.pattern.test(this.phone)
      this.checkPhone = res
    },
    // 流量配置
    async getTrafficConfig() {
      let res = await apiTrafficConfig()
      this.rentUrl = res.rentUrl
    },

    async onSubmit(value) {
      if (!this.isAgree) {
        return
      }

      // 推广记录
      await apiTrafficLog({
        mobile: this.phone,
        trafficCode: this.trafficCode
      })

      window.location.href = this.rentUrl
    },

    async getAgreementList() {
      this.agreementList = await apiAgreementList()
    },

    goProtocol(id) {
      this.$router.push(`/protocol/${id}`)
    },

    onChange(event) {
      console.log(event.detail, '@@@@')
    }
  }
}
</script>

<style lang="scss" scoped>
.tc {
  .top {
    width: 100%;
    height: 100%;
    position: relative;

    .bg {
      img {
        width: 100%;
        height: 100%;
      }
    }

    .text-desc {
      position: absolute;
      top: 2.5867rem;
      left: 0.8267rem;
      & > img {
        width: 3.36rem;
        padding-top: 0.2133rem;
      }
      .logo-img{
        width: 10vw;
        margin-bottom: 20px;
      }

      ul {
        margin-top: 0.6667rem;
        font-size: 0.32rem;

        li {
          text-align: center;
          padding-right: 0.48rem;
          // vertical-align: center;
          img {
            width: 0.32rem;
            height: 0.3467rem;
            padding-right: 0.1867rem;
            vertical-align: middle;
          }
        }
      }
    }

    h2 {
      font-size: 0.64rem;
      color: #fff;
      font-weight: 600;
    }

    h1 {
      font-size: 0.96rem;
      color: #fff;
      font-weight: 600;
    }

    ul li {
      float: left;
      font-size: 0.32rem;
      color: #fff;
    }

    // padding: 0.32rem 0.32rem 0rem 0.32rem;
    // box-sizing: border-box;
    // img {
    //   width: 100%;
    // }
  }

  .center {
    height: 5.3333rem;
    position: relative;
    border-radius: 0.5333rem;
    background: #fff;
    margin: 0 0.2667rem;
    padding: 0.5333rem;
    margin-top: -2.4rem;
    // margin-bottom: 65.8667vw;
    // padding-bottom: 2.6667rem;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;

    .mobile {
      position: relative;
      text-align: center;
      display: flex;
      align-items: center;
      // background: #f5f5f5;
      border-radius: 0.96rem;
      height: 100%;
      // .pre {
      //   display: flex;
      //   align-items: center;
      //   color: #333;
      //   position: absolute;
      //   font-weight: 500;
      //   font-size: 0.4267rem;
      //   top: 0.3733rem;
      //   left: 0.9333rem;

      //   .circle {
      //     display: inline-block;
      //     width: 0.1333rem;
      //     height: 0.1333rem;
      //     border-radius: 0.1333rem;
      //     background: #333;
      //     margin: 0 3.1vw;
      //   }

      //   .right-border {
      //     color: #cbcbcb;
      //   }
      // }
      // input {
      //   width: 8.4rem;
      //   height: 1.3333rem;
      //   border-radius: 1.3333rem;
      //   color: #cbcbcb;
      //   font-size: 0.4267rem;
      //   background: #f5f5f5;
      //   border: none;
      //   text-align: center;
      // }
      .phone-input {
        display: flex;
        align-items: center;
        background: #f5f5f5;
        border-radius: 0.96rem;
        // height: 50px;
        .left {
          display: flex;
          align-items: center;
          font-size: 0.4267rem;
          color: #333;
          border-right: 1px solid #eaebec;
          padding-left: 0.3733rem;

          .circle {
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #333;
            border-radius: 50%;
            margin: 0 0.32rem;
          }
        }
        .right /deep/ {
          background: #f5f5f5;
          border-radius: 0.96rem;
          height: 13.3333vw;
          font-size: 4.2667vw;
          .van-cell__value--alone {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
        & > span {
          flex: 1;
          background: #f5f5f5;
          width: 100%;
        }
      }
    }

    .protocol {
      display: flex;
      align-items: center;
      font-size: 0.32rem;
      margin: 0.32rem 0px;
      text-align: left;
      // padding-left: 0.8rem;

      em {
        color: #37a0ff;
        font-style: normal;
        // margin: 0 0.3vw;
      }
      input {
        margin-right: 1.4213vw;
      }
    }

    .go-zuji {
      text-align: center;
      button {
        border: none;
        border-radius: 1.3333rem;
        width: 8.4rem;
        height: 1.3333rem;
        background: #000;
        font-size: 0.4267rem;
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .footer {
    width: 100%;
    text-align: center;
    // position: fixed;
    // bottom: 0;
    margin-top: 6.5867rem;
    margin-bottom: 0.5333rem;
    a {
      font-size: 3.7333vw;
      color: #999;
    }
  }

  // padding-bottom: 4rem;
}
</style>
