<template>
  <div class="financial_product">
    <h2>钱不够？甄选借款产品</h2>

    <div class="item" v-for="item in list" :key="item.id" @click="goOutWeb(item)">
      <div class="left">
        <!-- <div class="title">安逸花-马上消费金融 <span>消费金融</span></div> -->
        <div class="logo">
          <img :src="item.productLogoUrl" alt="">
        </div>
        <div class="title">{{item.productName}} </div>
        <div class="price-rate">
          <div class="price">
            <p>{{item.quotaMax}}</p>
            <p>最高可借（元）</p>
          </div>
          <div class="rate">
            <p>年利率：{{item.yearRate}}</p>
            <p>借款期限：{{item.creditPeriod}}</p>
          </div>
        </div>
      </div>
      <div class="right">
        <span class="tag">智能推荐</span>
        <a @click="goProductPage(item.redirectUrl)">立即借钱</a>
      </div>
    </div>
  </div>
</template>

<script>
import {apiCreditPartnerList, apiChannelTrack} from './api'
import {authMixin} from '@/mixins/authMixin.js'
export default {
  name: 'financial',
  mixins: [authMixin],

  mounted() {
    this.queryCreditPartnerList(1)
  },

  data() {
    return {
      list: []
    }
  },

  methods: {
    async queryCreditPartnerList() {
      let res = await apiCreditPartnerList()
      this.list = res.result || []

      console.log(this.list, '@@@')
    },

    async goOutWeb(item) {
      await apiChannelTrack(item.id)
      if (item.redirectUrl) {
        window.location.href= item.redirectUrl
      }
    },

    async channelTrack(id) {
      await apiChannelTrack(id)
    },

    goProductPage(url) {
      window.location.href = url
    }
  }
}
</script>

<style lang="scss" scoped>
.financial_product {
    padding: .4rem;
    h2 {
        font-size: .3733rem;
        color: #222222;
        font-weight: normal;
        padding: .2133rem 0px;
    }

    .item {
        display: flex;
        // flex-wrap: nowrap;
        background-color: #fff;
        padding: 0rem 0rem .2667rem 0rem;
        border-radius: .2133rem;
        overflow: hidden;
        margin-bottom: .24rem;

        .left {
            flex: 1;
            position: relative;

            .logo {
              position: absolute;
              width: .5333rem;
              height: .5333rem;
              left: .32rem;
              top: .2933rem;
              img {
                width: 100%;
                height: 100%;
                vertical-align: top;
              }
            }

            .title {
                font-size: .3733rem;
                font-weight: bold;
                padding-left: 1.0667rem;
                height: 1.1733rem;
                line-height: 1.1733rem;
                position: relative;

                span {
                    background-color: #E4F5FF;
                    font-size: .2667rem;
                    color: #8DC3E2;
                    padding: .1067rem;
                    border-radius: 33px;
                    font-weight: normal;
                    margin-left: .2667rem;
                }
            }

            .price-rate {
                display: flex;

                .price {
                    padding-left: .32rem;
                    p:nth-child(1) {
                        color: #EC6144;
                        font-size: .64rem;
                    }

                    p:nth-child(2) {
                        color: #775718;
                        font-size: .32rem;
                    }

                }

                .rate {
                    padding-left: .7733rem;
                    padding-top: .2133rem;
                    p {
                        color: #775718;
                        font-size: .32rem;
                    }
                    p:nth-child(1) {
                        padding-bottom: .1333rem;
                    }
                }
            }
        }
        .right {
            width: 2.0267rem;
            // background-color: red;
            position: relative;

            span {
                font-size: .2667rem;
                color: #C98603;
                display: inline-block;
                width: 1.5467rem;
                height: .5067rem;
                padding-left: .2667rem;
                background-color: #FFF1D6;
                text-align: center;
                line-height: .5067rem;
                position: absolute;
                top: 0rem;
                right: 0rem;
                border-bottom-left-radius: .1067rem;

                &::before {
                    content: "";
                    display: block;
                    width: .2933rem;
                    height: .2933rem;
                    background: url(./images/star.png) no-repeat;
                    background-size: .2933rem auto;
                    position: absolute;
                    top: .1067rem;
                    right: 1.3867rem;
                }
            }

            a {
                position: absolute;
                display: block;
                width: 1.7067rem;
                height: .6933rem;
                text-align: center;
                line-height: .6933rem;
                font-size: .2667rem;
                background-color: #000;
                border-radius: .88rem;
                bottom: .4267rem;
            }
        }
    }
}
</style>