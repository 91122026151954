import { http } from '@/http/axios.js'

export function apiTrafficConfig() {
    return http({
      url: `/zjapi/traffic/config`,
      method: 'GET',
    })
}

export function apiAgreementList() {
  return http({
    url: `/zjapi/agreement/list`,
    method: 'GET',
  })
}

export function apiTrafficLog(data) {
  return http({
    url: `/zjapi/traffic/log`,
    method: 'POST',
    data
  })
}

export function hello() {
  return http({
    url: `/admin/test/hello`,
    method: 'GET',
  })
}
  